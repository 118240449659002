<template>
    <div class="container w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">
            Mijn gegevens
        </h2>
        <div v-if="showForm" class="font-base text-black" v-html="config.contactinfo.description[lang]">
        </div>
        <div v-if="showForm" class="relative flex flex-wrap">
            <div class="w-full relative">
                <div class="md:mt-6">
                    <form class="mt-8" @submit.prevent="updateContactInfo(local);">
                        <div class="mx-auto">
                            <div class="-mx-3 md:flex mb-6">
                                <div class="md:w-full py-1 px-3">
                                    <span class="px-1 text-sm text-gray-600 font-bold">Aanhef</span>
                                    <select v-model="local.contact.customertitleid"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <option disabled value="">Maak keuze</option>
                                        <option 
                                            v-for="title in $store.state.contactconfig.contacttitles" 
                                            v-bind:key="title.id" 
                                            :value="title.id">{{ title.name }}</option>
                                    </select>
                                </div>
                                <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('firstname') }">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        {{ firstnameLabel }}
                                        <span>*</span>
                                    </span>
                                    <input placeholder="" type="text" id="firstname"
                                        v-model="local.contact.firstname"
                                        
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                </div>                                    
                                <div class="md:w-full py-1 px-3" id="tm-contactinfo-middlename">
                                    <span class="px-1 text-sm text-gray-600 font-bold">Tussenvoegsel</span>
                                    <input placeholder="" type="text" id="middlename"
                                        v-model="local.contact.middlename"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                </div>
                                <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('lastname') }">
                                    <span class="px-1 text-sm text-gray-600 font-bold">
                                        Achternaam
                                        <span>*</span>
                                    </span>
                                    <input placeholder="" type="text" id="lastname"
                                        v-model="local.contact.lastname"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                </div>
                            </div>

                            <div
                                v-for="(address, index) of local.addresses" 
                                v-bind:key="address.id">
                                <div class="-mx-3 md:flex md:flex-row mb-6">                               
                                    <div class="md:w-1/2 py-1 px-3" v-bind:class="{ error: !isValid('street1', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            Straat
                                            <span>*</span>
                                        </span>
                                        <input placeholder="" type="text" name="street1" id="street1" 
                                            v-model="local.addresses[index].street1"
                                            
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    </div>
                                    <div class="md:w-1/4 py-1 px-3" v-bind:class="{ error: !isValid('street2', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            Huisnummer
                                            <span>*</span>
                                        </span>
                                        <input placeholder="" type="text" name="street2" id="street2" 
                                            v-model="local.addresses[index].street2"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    </div>
                                    <div class="md:w-1/4 py-1 px-3">
                                        <span class="px-1 text-sm text-gray-600 font-bold">Toevoeging</span>
                                        <input placeholder="" type="text" name="street3" id="street3" 
                                            v-model="local.addresses[index].street3"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    </div>
                                </div>
                                <div class="-mx-3 md:flex mb-6">                               
                                    <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('zip', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            Postcode
                                            <span>*</span>
                                        </span>
                                        <input placeholder="" type="text" name="zip" id="zip" 
                                            v-model="local.addresses[index].zip"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    </div>
                                    <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('city', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            Woonplaats
                                            <span>*</span>
                                        </span>
                                        <input placeholder="" type="text" name="city" id="city" 
                                            v-model="local.addresses[index].city"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    </div>
                                </div>
                                <div class="-mx-3 md:flex mb-6">                              
                                    <div class="md:w-full py-1 px-3" v-bind:class="{ error: !isValid('countrycode', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            Land
                                            <span>*</span>
                                        </span>
                                        <select v-model="local.addresses[index].countrycode"
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                            <option disabled value="">Maak keuze</option>
                                            <option
                                                v-for="country in $store.state.contactconfig.countries" 
                                                v-bind:key="country.code" 
                                                :value="country.code">{{ country.name }}</option>
                                        </select>                                            
                                    </div>
                                </div>                                
                            </div>

                            <div
                                v-for="(phonenumber, index) of local.phonenumbers" 
                                v-bind:key="phonenumber.id">                            
                                <div class="-mx-3 md:flex mb-6">                              
                                    <div class="md:w-1/2 py-1 px-3" v-bind:class="{ error: !isValid('number', index) }">
                                        <span class="px-1 text-sm text-gray-600 font-bold">
                                            Telefoonnummer
                                            <span>*</span>
                                        </span>
                                        <input placeholder="" type="text" id="number" 
                                            v-model="local.phonenumbers[index].number"
                                            
                                            class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                    </div>
                                </div>
                            </div>

                            <div class="-mx-3 md:flex mb-6">                              
                                <div class="md:w-1/2 py-1 px-3" v-bind:class="{ error: !isValid('birthdate') }">
                                    <span class="px-1 text-sm text-gray-600 font-bold">Geboortedatum</span>
                                    <input placeholder="" type="date" id="birthdate" name="birthdate" 
                                        v-model="local.contact.birthdate"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                </div>
                            </div>
                            <div class="-mx-3 md:flex mb-6">
                                <div class="md:w-1/2 py-1 px-3">
                                    <span class="px-1 text-sm text-gray-600 font-bold">Taal</span>
                                    <select v-model="local.contact.languagecode"
                                        class="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none">
                                        <option disabled value="">Maak keuze</option>
                                        <option value="NL">Nederlands</option>
                                        <option value="EN">Engels</option>
                                        <option value="FR">Frans</option>
                                        <option value="DU">Duits</option>
                                    </select>
                                </div>
                            </div>
                            <div class="-mx-3 md:flex mb-6"
                                v-for="field in customfields" 
                                    v-bind:key="field.id">
                                <div class="md:w-1/2 py-1 px-3">
                                    <component 
                                        :is="detect(field.fieldtype)"
                                        :id="field.id"
                                        :label="field.caption"
                                        :key="field.key"
                                        :options="field.customfieldvalues"
                                        :type="field.fieldtype"
                                        v-model="local.contact[field.fullkey]"
                                        :required="field.required === 'everywhere'"
                                        :error="getError(field.fullkey)"
                                    />
                                </div>
                            </div>
                            <div class="mx-3 flex mb-6">
                                <button type="submit"
                                    :disabled="!dirty || !valid"
                                    class="mt-3 text-lg font-semibold bg-gray-800 text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black disabled:bg-inactive">
                                    Opslaan
                                </button>
                                <button
                                    @click="$router.push('info')"
                                    class="mt-3 ml-3 text-lg font-semibold bg-gray-200 text-gray-800 rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black">
                                    Terug
                                </button>
                            </div>                           
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="isLoading">
            <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
        </div>
        <div v-if="error">
            <p>
                Er ging iets mis.
            </p>
            <p>
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </div>        
        <Modal ref="confirmationModal" title="Gegevens gewijzigd" icon="check-circle">
            Je persoonlijke gegevens zijn succesvol opgeslagen.
        </Modal>
        <Modal ref="errorModal" title="Niet gelukt" icon="exclamation-circle">
            <p class="text-lg">
                Er ging iets mis.
            </p>
            <p class="text-lg">
                Probeer het opnieuw of neem contact met ons op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>
    </div>      
</template>

<style lang="scss" scoped>
div.error {
    span {
        @apply text-error;
    }

    input {
        @apply border-error;
    }
}
</style>

<script>
import i18n from '@/plugins/i18n'
const moment = require("moment")
moment.locale(i18n.locale)

import { mapGetters } from 'vuex'
import Modal from '../components/Modal.vue'
import DateField from '../components/forms/DateField.vue'
import InputField from '../components/forms/InputField.vue'
import SelectField from '../components/forms/SelectField.vue'
import CheckBoxField from '../components/forms/CheckBoxField.vue'
const R = require('ramda')

const requiredContactFields = ['firstname', 'lastname'];
const otherContactFields    = ['birthdate'];
const requiredAddressFields = ['countrycode', 'zip', 'city', 'street1', 'street2'];
const requiredPhonenumberFields = ['number'];

export default {
    name: 'Home',
    title: 'Mijn Gegevens',
    components: {
        Modal,
        CheckBoxField,
        DateField,
        InputField,
        SelectField,
    },
  data() {
    return {
        dirty: false,
        local: {},
        isLoading: false,
        error: null,
    }
  },
  computed: {
      ...mapGetters(["config", "contact"]),
      showForm: function() {
          return this.isLoading === false && this.error === null && this.local.contact;
      },
      firstnameLabel: function() {
          if (this.config.initials) {
              return "Voorletter(s)";
          }
          return "Voornaam";
      },
      customfields: function() {
        const activatedFields = this.config.contactinfo.customFields;
        return this.$store.state.customfields.fields
            .filter(f => activatedFields.includes(f.key))
            .map(f => {
                f.fullkey = `c_${f.key}`;
                return f;
            })
            // sort in order of activatedFields
            .sort((a, b) => activatedFields.indexOf(a.key) - activatedFields.indexOf(b.key));
      },
      requiredCustomFields: function() {
          return this.customfields
            .filter(f => f.required === 'everywhere')
            .map(f => `c_${f.key}`);
      },
      valid: function() {
          const contactFieldsValid = requiredContactFields.every(this.isValid);
          const contactOptionalFieldsValid = otherContactFields.every(this.isValid);          
          const addressesValid = this.local.addresses.every(this.isAddressValid);
          const phonenumbersValid = this.local.phonenumbers.every(this.isPhonenumberValid);
          const customFieldsValid = this.customfields.every(f => this.isValid(f.fullkey));
          return contactFieldsValid && contactOptionalFieldsValid && addressesValid && phonenumbersValid && customFieldsValid;
      },      
  },
  methods: {
    isValid: function(key, index) {
        if (requiredContactFields.includes(key)) {
            return !!this.local.contact[key];
        }
        if (this.requiredCustomFields.includes(key)) {
            if (Array.isArray(this.local.contact[key]) && this.local.contact[key].length === 0) {
                return false;
            }
            return !!this.local.contact[key];
        }
        if (requiredAddressFields.includes(key)) {
            return !!this.local.addresses[index][key];
        }
        if (requiredPhonenumberFields.includes(key)) {
            return !!this.local.phonenumbers[index][key];
        }
        // Birthdate should be in the future
        if (key === "birthdate" && this.local.contact.birthdate) {
            return !(moment(this.local.contact.birthdate) > moment())
        }
        return true;
    },
    getError: function(field) {
        if (this.requiredCustomFields.includes(field)) {
            return this.isValid(field) ? "" : "Dit veld is verplicht";
        }
        return "";
    },
    isAddressValid: function(_, index) {
          return requiredAddressFields.every(f => this.isValid(f, index));
    },
    isPhonenumberValid: function(_, index) {
        return requiredPhonenumberFields.every(f => this.isValid(f, index));
    },
    updateContactInfo: async function(input) {
        this.isLoading = true;
        try {
            await this.$store.dispatch("updateContactInfo", input);
            this.$refs.confirmationModal.toggle();
            this.isLoading = false;
        }
        catch(err) {
            this.$refs.errorModal.toggle();
            this.isLoading = false;
        }
    },
    detect: function(fieldtype) {
        if (["int", "decimal", "string"].includes(fieldtype)) {
            return "InputField";
        }
        if (fieldtype === "date") {
            return "DateField";
        }
        if (fieldtype === "boolean") {
            return "CheckBoxField";
        }
        if (fieldtype.startsWith('select')) {
            return "SelectField";
        }
        return "TextField";
    }        
  },
  watch: {
    contact: {
      handler() {
          this.local = R.clone(this.contact);
      },
      immediate: true,
    },
    local: {
        handler() {
            if (this.local.contact.birthdate === "") {
                this.local.contact.birthdate = null;
            }
            this.dirty = !R.equals(this.local, this.contact);
        },
        deep: true,
    },
  },
  async created() {
    this.isLoading = true;
    try {
        if (!this.$store.state.contact.contact) {
            await this.$store.dispatch('setContactInfo');
        }
        if (this.$store.state.contactconfig.contacttitles.length === 0) {
            await this.$store.dispatch('setContactConfig');
        }
        if (this.$store.state.customfields.loaded !== true) {
            await this.$store.dispatch('setCustomFields');
        }
    } catch (ex) {
        this.error = true;
    } finally {
        this.isLoading = false;
    }
  },
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Contactinfo",
    })
  },
}
</script>
